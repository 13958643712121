import React, { useEffect, useRef, useState } from "react";
import styles from "./contact-info-component.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../slice/RootReducer";
import TGIcon from "../../../../../../shared/tg-icon";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import { Form, InputGroup, Placeholder, Spinner } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  getCRMProfileContactInfoPending,
  getProfileContactInfoPending,
  setIsEditable,
} from "../../../../slice/profileContactInfoSlice";
import {
  fetchCRMProfileRequest,
  fetchCountryRequest,
  fetchCityRequest,
  fetchStateRequest,
} from "../../../../slice/fetchProfileSlice";
import { fetchMyProfileRequest } from "../../../../slice/fetchMainMemberProfileSlice";
import TGInput from "../../../../../../shared/tg-input";
import {
  CRMProfileContactInfo,
  ProfileContactInfo,
} from "../../../../models/models";
import { useTranslation } from "react-i18next";
import { formatContact, isvalidContact } from "../../../../../../utils/helper";
import TGButtonVariants from "../../../../../../shared/tg-button-variants";
import TGCheckBox from "../../../../../../shared/tg-checkbox";
import termConditionData from "../../../../../../../public/json/termConditionsProfileComponents.json";
import TGTypeahead from "../../../../../../shared/tg-typeahead";
import { fetchCRMCountryCodeRequest } from "../../../../slice/fetchCrmProfileSlice";
  
interface Props {
  profileName?: string;
}

export const ContactInfo = (props: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [profileContactInfoData, setProfileContactInfoData] =
    useState<ProfileContactInfo>({
      addressLine1: "",
      addressLine2: "",
      country: "",
      countryCode: "",
      countryCodeSecondary: "",
      contactNumber: "",
      city: "",
      otherCity: "",
      state: "",
      postalCode: "",
      email: "",
      seqNo: "",
      addressType: "",
      areaCode: "",
    });
  const [crmprofileContactInfoData, setCRMProfileContactInfoData] =
    useState<CRMProfileContactInfo>({
      contactNumber: "",
      email: "",
      countryCode: "",
    });
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  let isTabView: boolean =
    useScreenSize().deviceSize.width >= 768 &&
    useScreenSize().deviceSize.width < 1024;

  type Country = {
    name: string;
    code: string;
    emoji: string;
    unicode: string;
    dial_code: string;
    image: string;
    nationality: string;
  };
  type State = {
    name: string;
    state_code: string;
    country_code: string;
  };
  type City = {
    country_code: string;
    country_name: string;
    city_code: string;
    name: string;
    state_code: string;
  };
  const [errors, setErrors] = useState<any>({});
  const [memberId, setMemberId] = useState("");
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedState, setSelectedState] = useState<State | null>(null);
  const [selectedCity, setSelectedCity] = useState<City | any>(null);
  const [selectedCountryCode, setSelectedCountryCode] =
    useState<Country | null>(null);
  const [filteredStates, setFilteredStates] = useState<State[]>([]);
  const [filteredCities, setFilteredCities] = useState<City[]>([]);
  const [isContactDisabled, setIsContactDisabled] = useState<boolean>(false);
  const [countryCodeValue, setCountryCodeValue] = useState(" ");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledState, setIsDisabledState] = useState(true);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [termsConditions, setTermsConditons] = useState<boolean>(false);
  const [otherCity, setOtherCity] = useState("");
  const [isToggleOpen, setIsToggleOpen] = useState<string>("");
  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [countryCodeResponse, setCountryCodeResponse] = useState<any>([]);
  // const [isEditable, setIsEditable] = useState(false);
  const isEditable = useSelector(
    (state: RootState) => state?.osciReducer?.profileContactInfo.isEditable
  );
  const countryResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.countryData
  );
  const cityResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.cityData
  );
  const stateResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.stateData
  );
  const crmProfileResponses = useSelector(
    (state: RootState) => state?.osciReducer?.fetchCrmMemberProfile
  );
  useEffect(() => {
    dispatch(fetchCountryRequest());
    dispatch(fetchCityRequest());
    dispatch(fetchStateRequest());
    dispatch(fetchCRMCountryCodeRequest());
  }, [dispatch]);

  useEffect(() => {
    setCountryData(countryResponse);
    setCityData(cityResponse);
    setStateData(stateResponse);
  }, [cityResponse, countryResponse, stateResponse]);

 
  useEffect(() => {
    setCountryCodeResponse(crmProfileResponses?.crmCountryCodeData)
  }, [crmProfileResponses]);

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isCityListed = filteredCities?.some(
    (city: any) =>
      city.name.toUpperCase() === profileContactInfoData.city?.toUpperCase()
  );

  const termConditionRef = useRef<any>(null);

  useEffect(() => {
    dispatch(setIsEditable(false));
  }, []);

  useEffect(() => {
    if (selectedCountryCode) {
      setCountryCodeValue(selectedCountryCode.dial_code);
    } else {
      if (selectedCountry) {
        setCountryCodeValue(selectedCountry.dial_code);
      }
      if (selectedCountry === null) {
        setCountryCodeValue("");
        setIsContactDisabled(false);
      }
    }
  }, [
    selectedCountry,
    profileContactInfoData.country || crmprofileContactInfoData.contactNumber,
  ]);

  useEffect(() => {
    const countryCode = countryData?.find(
      (country) => country.name === profileContactInfoData.country
    );
    setProfileContactInfoData({
      ...profileContactInfoData,
      countryCodeSecondary: countryCode?.code,
    });
    setErrors({ ...errors, countryCodeSecondary: "" });
  }, [profileContactInfoData.country]);

  useEffect(() => {
    if (selectedCountry) {
      if (props.profileName !== "CRM") {
        setProfileContactInfoData({
          ...profileContactInfoData,
          countryCode: selectedCountry?.dial_code,
        });
      }
      if (props.profileName === "CRM") {
        setCRMProfileContactInfoData({
          ...crmprofileContactInfoData,
          countryCode: selectedCountry?.code,
        });
      }
      setErrors({ ...errors, countryCode: "" });
      if (props.profileName !== "CRM") {
        const statePresent = stateData.filter(
          (state) => state.country_code === selectedCountry.code
        );
        if (statePresent.length > 0) {
          setIsDisabled(false);
          setIsDisabledState(false);
        } else {
          setIsDisabledState(true);
          setErrors({ ...errors, city: "", postalCode: "" });
        }

        setFilteredStates(
          stateData?.filter(
            (state) => state?.country_code === selectedCountry.code
          )
        );

        setFilteredCities(
          cityData?.filter(
            (city: any) => city?.country_code === selectedCountry.code
          )
        );
      }
    }
  }, [
    selectedCountry,
    profileContactInfoData.country,
    props.profileName,
    errors,
    profileContactInfoData,
    crmprofileContactInfoData,
    stateData,
    cityData,
  ]);

  useEffect(() => {
    if (props.profileName === "CRM") {
      dispatch(fetchCRMProfileRequest());
    } else {
      dispatch(fetchMyProfileRequest());
    }
  }, [dispatch]);

  const profileResponse = useSelector(
    (state: RootState) => state?.osciReducer?.MyProfileDeails.myprofileData
  );

  const profContactInfoSubmitResp = useSelector(
    (state: RootState) => state?.osciReducer?.profileContactInfo
  );

  const crmProfileResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.crmProfileData
  );

  useEffect(() => {
    let countryCode: any;
    let finalCountry: any;
    let countryObj;
    if (
      crmProfileResponse.countryCode == null ||
      crmProfileResponse.countryCode == undefined
    ) {
      
      countryObj = countryCodeResponse?.find(
        (country) =>
          country?.name?.split("(")[0].trim()
        === crmProfileResponse?.country
      );
    }
    if (
      crmProfileResponse.countryCode !== null &&
      crmProfileResponse.countryCode !== undefined
    ) {
      // countryCode = crmProfileResponse?.countryCode.split("(")[0];
      // finalCountry = countryCode.trim();
      countryObj = countryCodeResponse?.find(
        (country) => country.countrycodes ===  crmProfileResponse?.countryCode)
    }
    setSelectedCountry(countryObj !== undefined ? countryObj : "");
    setCountryCodeValue(countryObj?.code);
    setSelectedCountryCode(countryObj !== undefined ? countryObj : "");
    setCRMProfileContactInfoData({
      ...crmprofileContactInfoData,
      contactNumber: crmProfileResponse?.mobileNumber,
      email: crmProfileResponse?.emailId,
      countryCode: countryObj?.code !== undefined ? countryObj?.code : "",
    });
  }, [crmProfileResponse]);

  useEffect(() => {
    const countryObj = countryData?.find(
      (country) => country.code === profileResponse?.address?.countryCode
    );

    const cityObj = cityData?.find(
      (city) =>
        city.name.toLowerCase() ===
        profileResponse?.address?.city?.toLowerCase()
    );

    const stateObj = stateData?.find(
      (state) =>
        state.name.toLowerCase() ===
        profileResponse?.address?.state?.toLowerCase()
    );

    setSelectedCountry(countryObj !== undefined ? countryObj : "");
    setSelectedCountryCode(countryObj !== undefined ? countryObj : "");
    setSelectedState(stateObj !== undefined ? stateObj : "");
    // setSelectedCity(cityObj !== undefined ? cityObj : "");
    setProfileContactInfoData({
      ...profileContactInfoData,
      addressLine1: profileResponse?.address?.addressLine1,
      addressLine2: profileResponse?.address?.addressLine2,
      country: countryObj?.name !== undefined ? countryObj?.name : "",
      countryCode: countryObj?.code !== undefined ? countryObj?.code : "",
      countryCodeSecondary:
        countryObj?.code !== undefined ? countryObj?.dial_code : "",
      contactNumber: profileResponse?.phoneC?.number,
      city: profileResponse?.address?.city,
      state:
        profileResponse?.address?.state !== undefined
          ? profileResponse?.address?.state
          : "",
      postalCode: profileResponse?.address?.postalCode,
      email: profileResponse?.email,
      addressType: profileResponse?.address?.addressType,
      areaCode: profileResponse?.phoneC?.areaCode,
      seqNo: profileResponse?.phoneC?.seqNo,
    });
    // }, 1000);
  }, [profileResponse]);

  useEffect(() => {
    if (profContactInfoSubmitResp.isUpdateSuccess === true) {
      if (props.profileName !== "CRM") {
        dispatch(fetchMyProfileRequest());
      } else {
        dispatch(fetchCRMProfileRequest());
      }
      dispatch(setIsEditable(false));
      setIsChecked(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [profContactInfoSubmitResp.isUpdateSuccess]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name !== "contactNumber") {
      setProfileContactInfoData({
        ...profileContactInfoData,
        [name]: value,
      });
    } else if (name === "contactNumber" && isvalidContact(value)) {
      if (props.profileName !== "CRM") {
        setProfileContactInfoData({
          ...profileContactInfoData,
          contactNumber: formatContact(value),
        });
      } else {
        setCRMProfileContactInfoData({
          ...crmprofileContactInfoData,
          contactNumber: formatContact(value),
        });
      }
    }
    setErrors({ ...errors, [name]: "" });
  };

  const handleEditClick = () => {
    if (props.profileName !== "CRM") {
      setIsContactDisabled(true);
    }
    dispatch(setIsEditable(true));
  };

  const handleCountryChange = (selected: any) => {
    const country = selected.length ? selected[0] : null;
    setSelectedCountry(country);
    // setSelectedCountryCode(country);
    setSelectedState(null);
    setSelectedCity(null);
    setProfileContactInfoData({
      ...profileContactInfoData,
      countryCode: country?.dial_code,
      country: country?.name,
      state: "",
      city: "",
    });

    setErrors({ ...errors, country: "" });
    setIsContactDisabled(true);
    setIsToggleOpen("");
  };

  const handleCountryCodeChange = (selected: any) => {
    const country = selected.length ? selected[0] : null;
    setSelectedCountryCode(country);
    if (props.profileName !== "CRM") {
      setProfileContactInfoData({
        ...profileContactInfoData,
        countryCode: country?.dial_code,
      });
    setCountryCodeValue(country?.dial_code);

    } else {
      setCRMProfileContactInfoData({
        ...crmprofileContactInfoData,
        countryCode: country?.code,
      });
    setCountryCodeValue(country?.code);

    }
    setErrors({ ...errors, countryCode: "" });
    setIsToggleOpen("");
  };

  const handleStateChange = (selected: any) => {
    const state = selected.length ? selected[0] : null;
    setSelectedState(state);
    setProfileContactInfoData({
      ...profileContactInfoData,
      state: state?.name,
    });
    setErrors({ ...errors, state: "" });
    setIsToggleOpen("");
  };

  const handleCityChange = (selected: any) => {
    const city = selected.length ? selected[0] : null;
    if (city?.name === "Other (Please specify)") {
      setSelectedCity({ name: "Other (Please specify)" });
      setProfileContactInfoData({ ...profileContactInfoData, city: otherCity });
    } else {
      setSelectedCity(city);
      setProfileContactInfoData({
        ...profileContactInfoData,
        city: city?.name,
      });
      setOtherCity("");
    }
    setErrors({ ...errors, city: "", otherCity: "" });
    setIsToggleOpen("");
  };

  const handleKeyDown = (e: any) => {
    const isValidInput = /^[a-zA-Z ]{0,100}$/.test(e.key);
    if (!isValidInput) {
      e.preventDefault();
    }
  };

  const getFieldLabel = (fieldName: keyof ProfileContactInfo) => {
    const labels: { [key in keyof ProfileContactInfo]: string } = {
      addressLine1: t("signin_email_required"),
      country: t("signin_email_required"),
      city: t("signin_email_required"),
      countryCode: t("signin_email_required"),
      contactNumber: t("signin_email_required"),
      postalCode: t("signin_email_required"),
      email: t("signin_email_required"),
    };
    return labels[fieldName];
  };

  const getCRMFieldLabel = (fieldName: keyof CRMProfileContactInfo) => {
    const labels: { [key in keyof CRMProfileContactInfo]: string } = {
      countryCode: t("signin_email_required"),
      contactNumber: t("signin_email_required"),
    };
    return labels[fieldName];
  };

  const handleProfileContactUpdate = (
    event: React.FormEvent<HTMLButtonElement>
  ) => {
    const newErrors: Partial<ProfileContactInfo> = {};
    Object.entries(profileContactInfoData).forEach(([key, value]) => {
      if (!value?.trim()) {
        newErrors[key as keyof ProfileContactInfo] = getFieldLabel(
          key as keyof ProfileContactInfo
        );
      } else if (key === "email" && !isValidEmail(value)) {
        newErrors[key as keyof ProfileContactInfo] = t("reset_invalid_message");
      }

      if (
        key === "contactNumber" &&
        profileContactInfoData?.contactNumber.length < 3
      ) {
        newErrors[key as keyof ProfileContactInfo] = getFieldLabel(
          key as keyof ProfileContactInfo
        );
      }
    });
    setErrors(newErrors);
    var filterUndefinedError;
    const keys = Object.keys(newErrors) as (keyof ProfileContactInfo)[];
    filterUndefinedError = keys.filter((key) => newErrors[key] !== undefined);
    let termsCheck = false;
    setErrors({ ...errors, termsConditions: "error" });
    if (termConditionRef.current) {
      termsCheck = termConditionRef.current.checked;
    }
    let newError2 = {};
    filterUndefinedError?.forEach((e) => {
      if (e == "city" && !isDisabled) {
        if (selectedCity?.name === "Other (Please specify)" && !otherCity) {
          newError2 = { ...newError2, otherCity: "error" };
        } else {
          newError2 = { ...newError2, city: "error" };
        }
      } else if (e == "addressLine1") {
        newError2 = { ...newError2, addressLine1: "error" };
      } else if (e == "postalCode" && !isDisabled) {
        newError2 = { ...newError2, postalCode: "error" };
      } else if (e == "contactNumber") {
        newError2 = { ...newError2, contactNumber: "error" };
      } else if (e == "country") {
        newError2 = { ...newError2, country: "error" };
      }
    });

    if (termsCheck) {
      newError2 = { ...newError2, termsConditions: "" };
    } else {
      newError2 = { ...newError2, termsConditions: "error" };
    }
    setErrors(newError2);

    if (filterUndefinedError.length === 0 && termsCheck) {
      setErrors({ ...errors, termsConditions: "" });
      dispatch(
        getProfileContactInfoPending({
          addressLine1: profileContactInfoData.addressLine1,
          addressLine2: profileContactInfoData.addressLine2,
          country: profileContactInfoData.country,
          email: profileContactInfoData.email,
          countryCode: profileContactInfoData.countryCode,
          countryCodeSecondary: selectedCountry?.code,
          contactNumber: profileContactInfoData.contactNumber?.replaceAll(
            "-",
            ""
          ),
          city: profileContactInfoData.city,
          state: profileContactInfoData.state,
          postalCode: profileContactInfoData.postalCode,
          addressType: profileContactInfoData.addressType,
          areaCode: profileContactInfoData.areaCode,
          seqNo: profileContactInfoData.seqNo,
        })
      );
    }
  };

  const handleCRMProfileContactUpdate = (
    event: React.FormEvent<HTMLButtonElement>
  ) => {
    const newErrors: Partial<CRMProfileContactInfo> = {};
    Object.entries(crmprofileContactInfoData).forEach(([key, value]) => {
      if (!value?.trim()) {
        newErrors[key as keyof CRMProfileContactInfo] = getCRMFieldLabel(
          key as keyof CRMProfileContactInfo
        );
      } else if (key === "contactNumber") {
        if (crmprofileContactInfoData?.contactNumber?.length <= 2) {
          newErrors[key as keyof CRMProfileContactInfo] = getCRMFieldLabel(
            key as keyof CRMProfileContactInfo
          );
        }
      } else if (key === "countryCode") {
        if (crmprofileContactInfoData?.countryCode === undefined) {
          newErrors[key as keyof CRMProfileContactInfo] = getCRMFieldLabel(
            key as keyof CRMProfileContactInfo
          );
        }
      }
    });
    setErrors(newErrors);
    var filterUndefinedError;
    const keys = Object.keys(newErrors) as (keyof CRMProfileContactInfo)[];
    filterUndefinedError = keys.filter((key) => newErrors[key] !== undefined);
    let termsCheck = false;
    if (termConditionRef.current) {
      termsCheck = termConditionRef.current.checked;
    }
    setTermsConditons(!termsCheck);
    if (filterUndefinedError.length === 0 && termsCheck) {
      dispatch(
        getCRMProfileContactInfoPending({
          contactNumber: crmprofileContactInfoData.contactNumber,
          countryCode: selectedCountryCode?.countrycodes,
        })
      );
    }
  };

  const checkedChange = (e: any): void => {
    setIsChecked(!isChecked);
    if (termConditionRef.current) {
      const value = termConditionRef.current.checked;
      setTermsConditons(!value);
      if (value === true) {
        setErrors({ ...errors, termsConditions: "" });
      } else {
        setErrors({ ...errors, termsConditions: "error" });
      }
    }
  };

  const renderTermsConditionError = () => {
    return (
      <div className={styles.lifeStyleInfoTermsConditionsError}>
        <TGIcon icon="error-icon" fillColor="" size="16" />
        <div className={styles.lifeStyleInfoTermConditionText}>
          {t("enroll_terms_condition")}
        </div>
      </div>
    );
  };

  const handleOtherCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "otherCity") {
      setOtherCity(value);
      setProfileContactInfoData({ ...profileContactInfoData, city: value });
      setErrors({ ...errors, city: "", otherCity: "" });
    }
  };

  useEffect(() => {
    if (profileContactInfoData.city) {
      if (isCityListed) {
        setSelectedCity({ name: profileContactInfoData.city });
        setOtherCity("");
      } else {
        setSelectedCity({ name: "Other (Please specify)" });
        setOtherCity(profileContactInfoData.city);
      }
      setErrors({ ...errors, city: "", otherCity: "" });
    }
  }, [profileContactInfoData.city, isCityListed]);

  return (
    <>
      {!isEditable && (
        <div className={styles.profileContactInfoContainer}>
          <div className={styles.profileContactInfo}>
            <div className={styles.profileContactInfoHeadingContainer}>
              <div className={styles.profileContactInfoHeadingText}>
                {t("label_contact_information")}
              </div>
              {isDesktopView && (
                <div
                  onClick={handleEditClick}
                  className={styles.profileContactInfoHeadingButtonContainer}
                >
                  <div className={styles.profileContactInfoHeadingButton}>
                    <TGIcon icon={"edit-icon"} fillColor={""} size={"20px"} />
                    <span className={styles.editButtonText}>
                      {t("label_edit")}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {props.profileName !== "CRM" && (
              <div className={styles.profileContactInfoBodyContainer}>
                <div className={styles.profileContactInfoBodyHeadingContainer}>
                  {t("label_mailing_address")}
                </div>
                <div className={styles.profileContactInfoAddressContainer}>
                  <div
                    className={
                      styles.profileContactInfoBodyMailingAddressContainer
                    }
                  >
                    <div
                      className={styles.profileContactInfoBodyAddressContainer}
                    >
                      <div className={styles.profileContactInfoLabelContainer}>
                        <div className={styles.profileContactInfoLabel}>
                          {t("enroll_address_line1")}
                        </div>
                        <div className={styles.profileContactInfoText}>
                          {profileContactInfoData.addressLine1
                            ? profileContactInfoData.addressLine1?.toUpperCase()
                            : "-"}
                        </div>
                      </div>
                      <div className={styles.profileContactInfoLabelContainer}>
                        <div className={styles.profileContactInfoLabel}>
                          {t("enroll_address_line2").split("(")[0]}
                        </div>
                        <div className={styles.profileContactInfoText}>
                          {profileContactInfoData.addressLine2
                            ? profileContactInfoData.addressLine2?.toUpperCase()
                            : "-"}
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.profileContactInfoBodyCountryContainer}
                    >
                      <div className={styles.profileContactInfoLabelContainer}>
                        <div className={styles.profileContactInfoLabel}>
                          {t("enroll_country")}
                        </div>
                        <div className={styles.profileContactInfoText}>
                          {profileContactInfoData.country
                            ? profileContactInfoData.country?.toUpperCase()
                            : "-"}
                        </div>
                      </div>
                      <div className={styles.profileContactInfoLabelContainer}>
                        <div className={styles.profileContactInfoLabel}>
                          {t("enroll_city")}
                        </div>
                        <div className={styles.profileContactInfoText}>
                          {profileContactInfoData.city
                            ? profileContactInfoData.city?.toUpperCase()
                            : "-"}
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.profileContactInfoBodyCountryContainer}
                    >
                      <div className={styles.profileContactInfoLabelContainer}>
                        <div className={styles.profileContactInfoLabel}>
                          {t("label_state")}
                        </div>
                        <div className={styles.profileContactInfoText}>
                          {profileContactInfoData.state
                            ? profileContactInfoData.state?.toUpperCase()
                            : "-"}
                        </div>
                      </div>
                      <div className={styles.profileContactInfoLabelContainer}>
                        <div className={styles.profileContactInfoLabel}>
                          {t("enroll_postal")}
                        </div>
                        <div className={styles.profileContactInfoText}>
                          {profileContactInfoData.postalCode
                            ? profileContactInfoData.postalCode
                            : "-"}
                        </div>
                        <div className={styles.profileContactInfoText}>
                          {" "}
                          {}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {props.profileName !== "CRM" && (
              <TGIcon icon="divider-icon" fillColor="" size="" />
            )}
            <div className={styles.profileContactInfoAddressContainer}>
              <div className={styles.profileContactInfoBodyHeadingContainer}>
                {t("label_contact_details")}
              </div>
              <div className={styles.profileContactInfoAddressContainer}>
                <div
                  className={
                    styles.profileContactInfoBodyMailingAddressContainer
                  }
                >
                  <div
                    className={styles.profileContactInfoBodyAddressContainer}
                  >
                    <div className={styles.profileContactInfoLabelContainers}>
                      <div className={styles.profileContactInfoLabel}>
                        {t("profile_view_contact")}
                      </div>
                      <div className={styles.profileContactInfoText}>
                        {(profileContactInfoData?.contactNumber ||
                          crmprofileContactInfoData?.contactNumber) && (
                          <img
                            src={selectedCountry ? selectedCountry?.image : ""}
                            alt={selectedCountry?.name}
                            style={{
                              width: "30px",
                              height: "25px",
                            }}
                          ></img>
                        )}
                        <span className={styles["contact-info-number-details"]}>
                          {profileContactInfoData.contactNumber
                            ? countryCodeValue !== undefined &&
                              countryCodeValue !== null
                              ? countryCodeValue +
                                " " +
                                formatContact(
                                  profileContactInfoData.contactNumber
                                )
                              : formatContact(
                                  profileContactInfoData.contactNumber
                                )
                            : crmprofileContactInfoData.contactNumber
                            ? crmProfileResponse?.countryCode != null
                              ? crmProfileResponse?.countryCode +
                                " " +
                                formatContact(
                                  crmprofileContactInfoData.contactNumber
                                )
                              : countryCodeValue !== undefined &&
                                countryCodeValue !== null
                              ? crmProfileResponse?.country +
                                " (" +
                                countryCodeValue +
                                ")" +
                                " " +
                                formatContact(
                                  crmprofileContactInfoData.contactNumber
                                )
                              : formatContact(
                                  crmprofileContactInfoData.contactNumber
                                )
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TGIcon icon="divider-icon" fillColor="" size="" />
            <div className={styles.profileContactInfoAddressContainer}>
              <div className={styles.profileContactInfoBodyHeadingContainer}>
                {t("label_email_address")}
              </div>
              <div className={styles.profileContactInfoAddressContainer}>
                <div
                  className={
                    styles.profileContactInfoBodyMailingAddressContainer
                  }
                >
                  <div
                    className={styles.profileContactInfoBodyAddressContainer}
                  >
                    <div className={styles.profileContactInfoLabelContainers}>
                      <div className={styles.profileContactInfoLabel}>
                        {t("label_reset_email")}
                      </div>
                      <div className={styles.profileContactInfoText}>
                        {profileContactInfoData.email
                          ? profileContactInfoData.email
                          : crmprofileContactInfoData.email
                          ? crmprofileContactInfoData.email
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {!isDesktopView && (
              <div
                onClick={handleEditClick}
                className={styles.profileContactInfoHeadingButtonContainer}
              >
             
                <div className={styles.profileContactInfoHeadingButton}>
                  <TGIcon icon={"edit-icon"} fillColor={""} size={"20px"} />
                  Edit
                </div>
              </div>
            )} */}
          </div>
        </div>
      )}
      {isEditable && (
        <div className={styles.profileContactInfoContainer}>
          <div className={styles.profileContactInfo}>
            <div className={styles.profileContactInfoHeadingContainer}>
              <div className={styles.profileContactInfoHeadingText}>
                {t("label_contact_information")}
              </div>
              {/* <div className={styles.profileContactInfoHeadingButtonContainer}>
          
            <div className={styles.profileContactInfoHeadingButton}>
              <TGIcon
                icon={"edit-icon"}
                fillColor={""}
                size={"20px"}
              />
                Edit
            </div>
          </div> */}
            </div>
            {props.profileName !== "CRM" && (
              <div className={styles.profileContactInfoBodyContainer}>
                <div className={styles.profileContactInfoBodyHeadingContainer}>
                  {t("label_mailing_address")}
                </div>
                <div className={styles.profileContactInfoAddressContainer}>
                  <div
                    className={
                      styles.profileContactInfoBodyMailingAddressContainer
                    }
                  >
                    <div
                      className={styles.profileContactInfoBodyAddressContainer}
                    >
                      <div className={styles.profileContactInfoLabelContainer}>
                        {/* <div className={styles.profileContactInfoLabel}> Address Line 1 </div> */}
                        <TGInput
                          isEnroll={true}
                          label={t("enroll_address_line1")}
                          value={profileContactInfoData.addressLine1}
                          name="addressLine1"
                          onChange={handleChange}
                          isError={!!errors.addressLine1}
                          errorLabel={t("Address_line1_required")}
                          placeholder="Example: House No., Alley, Road"
                          validation="address"
                        />
                        <TGInput
                          isEnroll={true}
                          label={t("enroll_address_line2")}
                          value={profileContactInfoData.addressLine2}
                          name="addressLine2"
                          onChange={handleChange}
                          validation="address"
                        />
                      </div>
                    </div>
                    <div className={styles.primaryEnrollNationality}>
                      <div className={styles.nationalityContainer}>
                        <div>
                          <Form.Group
                            style={{
                              flex: "1",
                              padding: "0",
                              width: !isDesktopView ? "100%" : "",
                            }}
                          >
                            <Form.Label className={styles.tgFormInputLabel}>
                              {t("enroll_country")}
                            </Form.Label>
                            {/* //CRM edit contact-info */}
                            <TGTypeahead
                              id="country"
                              labelKey={(option: any) => `${option.name}`}
                              options={countryData}
                              onChange={handleCountryChange}
                              selected={
                                selectedCountry ? [selectedCountry] : []
                              }
                              isOpen={isToggleOpen === "country"}
                              setIsOpen={(id) => setIsToggleOpen(id)}
                              showImage={true}
                            />
                            {errors.country && (
                              <Form.Label className={styles.errorLabel}>
                                {t("enroll_country_required")}
                              </Form.Label>
                            )}
                          </Form.Group>
                        </div>
                      </div>
                      <div></div>
                    </div>
                    <div className={styles.primaryEnrollNationality}>
                      <div className={styles.nationalityContainer}>
                        <div>
                          <Form.Group
                            style={{
                              flex: "1",
                              padding: "0",
                              width: !isDesktopView ? "100%" : "",
                            }}
                          >
                            <Form.Label className={styles.tgEnrollInputLabel}>
                              {t("enroll_city")}
                            </Form.Label>

                            <TGTypeahead
                              id="city"
                              labelKey={(option: any) => option.name}
                              options={[
                                ...filteredCities,
                                { name: "Other (Please specify)" },
                              ]}
                              onChange={handleCityChange}
                              selected={
                                selectedCity
                                  ? isCityListed
                                    ? [selectedCity]
                                    : [{ name: "Other (Please specify)" }]
                                  : []
                              }
                              isOpen={isToggleOpen === "city"}
                              setIsOpen={(id) => setIsToggleOpen(id)}
                            />
                            {errors.city && (
                              <Form.Label className={styles.errorLabel}>
                                {t("enroll_city_required")}
                              </Form.Label>
                            )}
                          </Form.Group>
                        </div>
                      </div>
                      <div>
                        {!isCityListed && (
                          <div
                            style={{
                              flex: "1",
                              padding: "0",
                              width: !isDesktopView ? "100%" : "",
                            }}
                          >
                            <TGInput
                              isEnroll={true}
                              label={t("label_other_city_province")}
                              value={profileContactInfoData.city}
                              name="otherCity"
                              onChange={handleOtherCityChange}
                              isError={!!errors.otherCity}
                              errorLabel={
                                !profileContactInfoData.city
                                  ? t("error_enroll_other_city_province")
                                  : " "
                              }
                              customClassName={styles.setFlex}
                              disables={selectedCity === null}
                              validation="address"
                              maxLength={40}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={styles.profileContactInfoBodyAddressContainer}
                    >
                      <Form.Group
                        style={{
                          flex: "1",
                          padding: "0",
                          width: !isDesktopView ? "100%" : "",
                        }}
                      >
                        <Form.Label className={styles.tgEnrollInputLabel}>
                          {t("enroll_state")}
                        </Form.Label>
                        <TGTypeahead
                          id="state"
                          labelKey={(option: any) => `${option.name}`}
                          options={filteredStates}
                          onChange={handleStateChange}
                          selected={selectedState ? [selectedState] : []}
                          isOpen={isToggleOpen === "state"}
                          setIsOpen={(id) => setIsToggleOpen(id)}
                        />
                        {/* </div> */}
                      </Form.Group>
                      <div
                        style={{
                          flex: "1",
                          padding: "0",
                          width: !isDesktopView ? "100%" : "",
                        }}
                      >
                        <TGInput
                          isEnroll={true}
                          label={t("enroll_postal")}
                          value={profileContactInfoData.postalCode}
                          name="postalCode"
                          onChange={handleChange}
                          isError={errors.postalCode}
                          errorLabel={t("enroll_postal_required")}
                          disables={isDisabled}
                          customClassName={styles.setFlex}
                          validation="postal"
                        />
                      </div>

                      {/* </div> */}
                      {/* <div className={styles.profileContactInfoLabelContainer}>
                        <div className={styles.profileContactInfoLabel}> State </div>
                        <div className={styles.profileContactInfoText}> - </div>
                    </div>
                    <div className={styles.profileContactInfoLabelContainer}>
                        <div className={styles.profileContactInfoLabel}> Postal Code </div>
                        <div className={styles.profileContactInfoText}> 10110 </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {props.profileName !== "CRM" && (
              <TGIcon icon="divider-icon" fillColor="" size="" />
            )}
            <div className={styles.profileContactInfoAddressContainer}>
              <div className={styles.profileContactInfoBodyHeadingContainer}>
                {t("label_contact_details")}
              </div>
              <div className={styles.profileContactInfoAddressContainer}>
                <div
                  className={
                    styles.profileContactInfoBodyMailingAddressContainer
                  }
                >
                  <div
                    className={styles.profileContactInfoBodyAddressContainer}
                  >
                    <div className={styles.profileContactInfoLabelContainer}>
                      <Form.Label className={styles.tgEnrollInputLabel}>
                        {t("profile_view_contact")}
                      </Form.Label>
                      <Form.Group
                        style={{
                          flex: "1",
                          paddingRight: "5px",
                          width: !isDesktopView ? "100%" : "50%",
                        }}
                      >
                        <InputGroup>
                          <TGTypeahead
                            className={styles.wrapper}
                            id="countryCode"
                            labelKey={(option: any) => props.profileName == "CRM" ? option.code :`${option.dial_code}`}
                            options={props.profileName == "CRM" ? countryCodeResponse: countryData}
                            onChange={handleCountryCodeChange}
                            selected={
                              selectedCountryCode ? [selectedCountryCode] : []
                            }
                            isOpen={isToggleOpen === "countryCode"}
                            setIsOpen={(id) => setIsToggleOpen(id)}
                            showImage={true}
                          />
                          <Form.Control
                            className={`${styles["tg-input-box"]} ${
                              styles.borderContact
                            }  ${
                              !!errors.contactNumber && styles["tg-input-error"]
                            }`}
                            onChange={handleChange}
                            type={"text"}
                            name="contactNumber"
                            maxLength={16}
                            value={formatContact(
                              profileContactInfoData.contactNumber ||
                                crmprofileContactInfoData.contactNumber
                            )}
                            placeholder="00-000-0000"
                          />
                          {!!errors.contactNumber && (
                            <div className={styles["tg-input-error-icon"]}>
                              <TGIcon
                                icon="error-icon"
                                fillColor=""
                                size="16"
                              />
                            </div>
                          )}
                        </InputGroup>
                        {errors.countryCode && errors.contactNumber ? (
                          <span>
                            {errors.contactNumber && (
                              <Form.Label className={styles.errorLabel}>
                                {t("profile_view_contact_err")}
                              </Form.Label>
                            )}
                          </span>
                        ) : (
                          <span className={styles["error-container"]}>
                            {errors.countryCode && (
                              <Form.Label className={styles.errorLabel}>
                                {t("label_country_code_error")}
                              </Form.Label>
                            )}
                            {errors.contactNumber && (
                              <Form.Label className={styles.errorLabel}>
                                {t("profile_view_contact_err")}
                              </Form.Label>
                            )}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TGIcon icon="divider-icon" fillColor="" size="" />
            <div className={styles.profileContactInfoAddressContainer}>
              <div className={styles.profileContactInfoBodyHeadingContainer}>
                {t("label_email_address")}
              </div>
              <div className={styles.profileContactInfoAddressContainer}>
                <div
                  className={
                    styles.profileContactInfoBodyMailingAddressContainer
                  }
                >
                  <div
                    className={styles.profileContactInfoBodyAddressContainer}
                  >
                    <div className={styles.profileContactInfoLabelContainer}>
                      <TGInput
                        // customClassName={styles.profilePostcode}
                        isEnroll={true}
                        label={t("label_reset_email")}
                        value={
                          profileContactInfoData.email ||
                          crmprofileContactInfoData.email
                        }
                        name="email"
                        onChange={handleChange}
                        isError={!!errors.email}
                        errorLabel={errors.email}
                        placeholder="Example: THAI@gmail.com"
                        disables={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              isDesktopView
                ? styles.personalInfoacknowledgementContainer
                : styles.personalInfoacknowledgementMobileContainer
            }
          >
            <div
              className={
                isDesktopView
                  ? styles.personalInfoacknowledgement
                  : styles.personalInfoacknowledgementMobile
              }
            >
              <div className={styles.checkTermsConditions}>
                <TGCheckBox
                  isHtmlContent={true}
                  htmlContent={termConditionData.termConditionHeadingtext}
                  onChange={checkedChange}
                  checkref={termConditionRef}
                  className="checkLabel"
                  customLabel="checkLabel"
                />

                {!isDesktopView &&
                  (errors.termsConditions || termsConditions) &&
                  renderTermsConditionError()}

                {/* {!isDesktopView && renderSaveButton()} */}
                {isDesktopView && (
                  <div className={styles.PrivacyNoticeContainer}>
                    {
                      <span
                        className={styles.personalInfoPrivacyNoticeText}
                        dangerouslySetInnerHTML={{
                          __html: termConditionData.privacyNotice,
                        }}
                      ></span>
                    }
                  </div>
                )}

                {!isDesktopView && (
                  <div
                    className={
                      isDesktopView
                        ? styles.submitProfContactInfoButton
                        : styles.submitProfContactInfoMobileButton
                    }
                  >
                    <div>
                      <div>
                        {" "}
                        <TGButtonVariants
                          label={
                            profContactInfoSubmitResp.isLoading ? "" : "Save"
                          }
                          disabled={profContactInfoSubmitResp.isLoading}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="30px"
                          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                          bgColor="#684B9B"
                          padding="16px"
                          textColor="#FFFFFF"
                          fontFamily="Inter"
                          fontSize="16px"
                          fontStyle="normal"
                          fontWeight="700"
                          lineHeight="160%"
                          borderDisabled="0.791px solid #E3D4FE"
                          bgColorDisabled="#E3D4FE"
                          boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                          bgColorHover="#684B9B"
                          borderHover="0.791px solid #E3D4FE"
                          boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                          height="50px"
                          width={"100%"}
                          onClick={
                            props.profileName !== "CRM"
                              ? handleProfileContactUpdate
                              : handleCRMProfileContactUpdate
                          }
                        >
                          {" "}
                          {profContactInfoSubmitResp.isLoading ? (
                            <>
                              <Spinner
                                animation="grow"
                                variant="light"
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  margin: "5px",
                                }}
                              />
                              <Spinner
                                animation="grow"
                                variant="light"
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  margin: "5px",
                                }}
                              />
                              <Spinner
                                animation="grow"
                                variant="light"
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  margin: "5px",
                                }}
                              />
                            </>
                          ) : null}
                        </TGButtonVariants>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {isDesktopView && (
              <div
                className={
                  isDesktopView
                    ? styles.submitProfContactInfoButton
                    : styles.submitProfContactInfoMobileButton
                }
              >
                <div>
                  <div>
                    {" "}
                    <TGButtonVariants
                      label={profContactInfoSubmitResp.isLoading ? "" : "Save"}
                      disabled={profContactInfoSubmitResp.isLoading}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="30px"
                      boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                      bgColor="#684B9B"
                      padding="16px"
                      textColor="#FFFFFF"
                      fontFamily="Inter"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="700"
                      lineHeight="160%"
                      borderDisabled="0.791px solid #E3D4FE"
                      bgColorDisabled="#E3D4FE"
                      boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                      bgColorHover="#684B9B"
                      borderHover="0.791px solid #E3D4FE"
                      boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                      height="50px"
                      width={"100%"}
                      onClick={
                        props.profileName !== "CRM"
                          ? handleProfileContactUpdate
                          : handleCRMProfileContactUpdate
                      }
                    >
                      {" "}
                      {profContactInfoSubmitResp.isLoading ? (
                        <>
                          <Spinner
                            animation="grow"
                            variant="light"
                            style={{
                              width: "10px",
                              height: "10px",
                              margin: "5px",
                            }}
                          />
                          <Spinner
                            animation="grow"
                            variant="light"
                            style={{
                              width: "10px",
                              height: "10px",
                              margin: "5px",
                            }}
                          />
                          <Spinner
                            animation="grow"
                            variant="light"
                            style={{
                              width: "10px",
                              height: "10px",
                              margin: "5px",
                            }}
                          />
                        </>
                      ) : null}
                    </TGButtonVariants>
                  </div>
                </div>
              </div>
            )}

            {!isDesktopView && (
              <div className={styles.PrivacyNoticeContainer}>
                {
                  <span
                    className={styles.personalInfoPrivacyNoticeText}
                    dangerouslySetInnerHTML={{
                      __html: termConditionData.privacyNotice,
                    }}
                  ></span>
                }
              </div>
            )}
          </div>
          <div>
            {isDesktopView &&
              (errors.termsConditions || termsConditions) &&
              renderTermsConditionError()}
          </div>
        </div>
      )}
    </>
  );
};
