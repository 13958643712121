import React from "react";
import styles from "./crmprofile.module.scss";
import { MyProfile } from "../rop-profile/profile-component";

const CrmProfile: React.FC = () => {
  return (
    <>
      <MyProfile profileName={"CRM"} />
    </>
  );
};

export default CrmProfile;
